<template>
  <div v-if="item" class="el_1_189c00803bbe27a68c5fc0b9f7a4f67b mx-auto">
    <div class="el_2_189c00803bbe27a68c5fc0b9f7a4f67b flex space-x-3 text-left">
      <div class="el_3_189c00803bbe27a68c5fc0b9f7a4f67b flex-1">
        <div class="el_4_189c00803bbe27a68c5fc0b9f7a4f67b flex flex-col space-y-3">
          <TgPanel class="flex-1">
            <template #header>
              Flight Details
            </template>

            <template #header-buttons>
              <!-- <tg-btn color="danger" class="mr-1" :disabled="loading" v-if="$auth.isAdmin() && item.operationalstatus != 'DX'" @click="update_status('DX')">
                DX
              </tg-btn>
              <tg-btn color="success" class="mr-1" :disabled="loading" v-if="$auth.isAdmin() && item.operationalstatus == 'DX'" @click="update_status('OK')">
                OK
              </tg-btn> -->
              <tg-btn color="gray" class="mr-1" :disabled="loading" @click="refresh">
                Refresh
              </tg-btn>
            </template>
            <template #content>
              <TgLoader v-if="loading" class="mx-auto my-20" />
              <div v-if="!loading" class="el_5_189c00803bbe27a68c5fc0b9f7a4f67b">
                <FlightKeyVal :cols="flight_cols" :item="item" />
              </div>
            </template>
          </TgPanel>
          <div class="el_6_189c00803bbe27a68c5fc0b9f7a4f67b flex flex-1 space-x-3">
            <TgPanel class="flex-1">
              <template #header>
                Departures
              </template>
              <template #content>
                <TgLoader v-if="loading" class="mx-auto my-20" />
                <div v-if="!loading" class="el_7_189c00803bbe27a68c5fc0b9f7a4f67b">
                  <FlightKeyVal :cols="std_cols" :item="item" />
                </div>
              </template>
            </TgPanel>
            <TgPanel class="flex-1">
              <template #header>
                Arrivals
              </template>
              <template #content>
                <TgLoader v-if="loading" class="mx-auto my-20" />
                <div v-if="!loading" class="el_8_189c00803bbe27a68c5fc0b9f7a4f67b">
                  <FlightKeyVal :cols="sta_cols" :item="item" />
                </div>
              </template>
            </TgPanel>
          </div>
          <div class="el_9_189c00803bbe27a68c5fc0b9f7a4f67b flex flex-1 space-x-3">
            <TgPanel class="flex-1">
              <template #header>
                Delays
              </template>
              <template #content>
                <TgLoader v-if="loading" class="mx-auto my-20" />
                <div v-else class="el_10_189c00803bbe27a68c5fc0b9f7a4f67b flex text-center">
                  <div class="el_11_189c00803bbe27a68c5fc0b9f7a4f67b flex-1">
                    <div class="el_12_189c00803bbe27a68c5fc0b9f7a4f67b text-3xl font-bold text-center">
                      {{ item.departuredelay }} <small class="text-sm text-gray-600 uppercase">mins</small>
                    </div>
                    <div class="el_13_189c00803bbe27a68c5fc0b9f7a4f67b text-sm text-gray-600">
                      Impact to Departure
                    </div>
                  </div>
                  <div class="el_14_189c00803bbe27a68c5fc0b9f7a4f67b flex-1">
                    <div class="el_15_189c00803bbe27a68c5fc0b9f7a4f67b text-3xl font-bold text-center">
                      {{ item.arrivaldelay }} <small class="text-sm text-gray-600 uppercase">mins</small>
                    </div>
                    <div class="el_16_189c00803bbe27a68c5fc0b9f7a4f67b text-sm text-gray-600">
                      Impact to Arrival
                    </div>
                  </div>
                </div>
              </template>
            </TgPanel>
            <FlightImpact label=" Ground Time" :value="item.ground_time" suffix="mins" />
          </div>
        </div>
      </div>
      <div class="el_18_189c00803bbe27a68c5fc0b9f7a4f67b flex-1">
        <div class="el_19_189c00803bbe27a68c5fc0b9f7a4f67b flex flex-col space-y-3">
          <TgPanel class="flex-1">
            <template #header>
              Line of Flying
            </template>
            <template #content>
              <TgLoader v-if="lof_loading" class="mx-auto my-20" />
              <div v-else class="el_20_189c00803bbe27a68c5fc0b9f7a4f67b">
                <div class="el_21_189c00803bbe27a68c5fc0b9f7a4f67b flex items-center px-1 border-b border-gray-200 hover:bg-gray-100">
                  <div class="el_22_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm uppercase">
                    Sector
                  </div>
                  <div class="el_23_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm text-center uppercase">
                    Flight
                  </div>
                  <div class="el_24_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm text-center uppercase">
                    Day
                  </div>
                  <div class="el_25_189c00803bbe27a68c5fc0b9f7a4f67b text-sm uppercase">
                    Departure
                  </div>
                  <div class="el_26_189c00803bbe27a68c5fc0b9f7a4f67b ml-5 text-sm uppercase">
                    Arrival
                  </div>
                </div>
                <div v-for="l in lof_data" :key="l.aircrafttail" class="flex items-center px-1 border-b border-gray-200 hover:bg-gray-100" :class="l.flrkey == flrkey ? 'bg-primary-100 font-bold' : ''">
                  <div class="el_27_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm text-gray-600 uppercase">
                    {{ l.sector }}
                  </div>
                  <div class="el_28_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm text-gray-600 uppercase">
                    {{ l.flightnumber }}
                  </div>
                  <div class="el_29_189c00803bbe27a68c5fc0b9f7a4f67b flex-1  text-sm text-gray-600 uppercase">
                    {{ l.day }}
                  </div>
                  <div class="el_30_189c00803bbe27a68c5fc0b9f7a4f67b text-lg">
                    {{ l.std }}
                  </div>
                  <div class="el_31_189c00803bbe27a68c5fc0b9f7a4f67b ml-5 text-lg">
                    {{ l.sta }}
                  </div>
                </div>
              </div>
            </template>
          </TgPanel>

          <div class="flex-1 flex flex-col space-y-3">
            <div class="flex-1 flex space-x-3">
              <TgPanel class="flex-1">
                <template #header>
                  First PAX
                </template>
                <template #content>
                  <TgLoader v-if="loading" class="mx-auto my-20" />
                  <div v-if="!loading" class="el_32_189c00803bbe27a68c5fc0b9f7a4f67b">
                    <FlightKeyVal :cols="p_pax_cols" :item="item" />
                  </div>
                </template>
              </TgPanel>

              <TgPanel class="flex-1">
                <template #header>
                  Business PAX
                </template>
                <template #content>
                  <TgLoader v-if="loading" class="mx-auto my-20" />
                  <div v-if="!loading" class="el_32_189c00803bbe27a68c5fc0b9f7a4f67b">
                    <FlightKeyVal :cols="j_pax_cols" :item="item" />
                  </div>
                </template>
              </TgPanel>
            </div>
            <div class="flex-1 flex space-x-3">
              <TgPanel class="flex-1">
                <template #header>
                  Premium PAX
                </template>
                <template #content>
                  <TgLoader v-if="loading" class="mx-auto my-20" />
                  <div v-if="!loading" class="el_33_189c00803bbe27a68c5fc0b9f7a4f67b">
                    <FlightKeyVal :cols="w_pax_cols" :item="item" />
                  </div>
                </template>
              </TgPanel>
              <TgPanel class="flex-1">
                <template #header>
                  Economy PAX
                </template>
                <template #content>
                  <TgLoader v-if="loading" class="mx-auto my-20" />
                  <div v-if="!loading" class="el_34_189c00803bbe27a68c5fc0b9f7a4f67b">
                    <FlightKeyVal :cols="y_pax_cols" :item="item" />
                  </div>
                </template>
              </TgPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="el_35_189c00803bbe27a68c5fc0b9f7a4f67b mt-3">
      <flight-submits :flrkey="flrkey" />
    </div>
  </div>
</template>

<script>
import { API } from "@/api";
import TgLoader from "@/components/Common/TgLoader";
import TgPanel from "@/components/Common/TgPanel";
import FlightKeyVal from "@/components/Touchpoint/Schedules/Flight/FlightKeyVal.vue";
import FlightImpact from "@/components/Touchpoint/Schedules/Flight/FlightImpact.vue";
import FlightSubmits from "@/components/Touchpoint/Schedules/Flight/FlightSubmits.vue";
import dayjs from "dayjs";

import TgBtn from "@/components/Common/TgBtn";

import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

import _map from "lodash/map";
export default {
  components: {
    TgLoader,
    TgPanel,
    FlightKeyVal,
    FlightImpact,
    FlightSubmits,
    TgBtn,
  },
  props: {
    flrkey: String,
  },
  data() {
    return {
      loading: false,
      lof_loading: false,
      reports: [],
      lof_data: [],
      item: null,
      std_cols: [
        {
          field: "std",
          name: "Scheduled",
          css: "text-left",
          type: "time",
        },
        {
          field: "etd",
          name: "Estimated",
          css: "text-left",
          type: "time",
        },
        { field: "ltd", name: "Live", css: "text-left", type: "time" },
        { field: "atd", name: "Actual", css: "text-left", type: "time" },
      ],
      sta_cols: [
        {
          field: "sta",
          name: "Scheduled",
          css: "text-left",
          type: "time",
        },
        {
          field: "eta",
          name: "Estimated",
          css: "text-left",
          type: "time",
        },
        { field: "lta", name: "Live", css: "text-left", type: "time" },
        { field: "ata", name: "Actual", css: "text-left", type: "time" },
      ],

      p_pax_cols: [
        { field: "p_capacity", name: "P Capacity", css: "text-right" },
        {
          field: "p_pax_adjustment",
          name: "P Adjustment",
          row_css: " bg-primary-100 italic",
          css: "text-right",
        },
        { field: "p_first", name: "P Booked", css: "text-right" },
        { field: "p_first_boarded", name: "P Boarded", css: "text-right" },
        {
          field: "p_first_notboarded",
          name: "P Not Boarded",
          css: "text-right",
        },
      ],
      j_pax_cols: [
        { field: "j_capacity", name: "J Capacity", css: "text-right" },
        {
          field: "j_pax_adjustment",
          name: "J Adjustment",
          row_css: " bg-primary-100 italic",
          css: "text-right",
        },
        { field: "j_business", name: "J Booked", css: "text-right" },
        { field: "j_boarded", name: "J Boarded", css: "text-right" },
        { field: "j_notboarded", name: "J Not Boarded", css: "text-right" },
      ],
      w_pax_cols: [
        { field: "w_capacity", name: "W Capacity", css: "text-right" },
        {
          field: "w_pax_adjustment",
          name: "W Adjustment",
          row_css: " bg-primary-100 italic",
          css: "text-right",
        },
        { field: "w_premium", name: "W Booked", css: "text-right" },
        { field: "w_boarded", name: "W Boarded", css: "text-right" },
        { field: "w_notboarded", name: "W Not Boarded", css: "text-right" },
      ],

      y_pax_cols: [
        { field: "y_capacity", name: "Y Capacity", css: "text-right" },
        {
          field: "y_pax_adjustment",
          name: "Y Adjustment",
          row_css: " bg-primary-100 italic",
          css: "text-right",
        },
        {
          field: "z_unallocated",
          name: "Unallocated",
          row_css: " bg-yellow-100 italic",
          css: "text-right",
        },
        { field: "y_economy", name: "Y Booked", css: "text-right" },
        { field: "y_boarded", name: "Y Boarded", css: "text-right" },
        { field: "y_notboarded", name: "Y Not Boarded", css: "text-right" },
      ],
      lof: [{ field: "aircrafttail", name: "Tail", css: "text-right" }],
      flight_cols: [
        {
          field: "operationalstatus",
          name: "Operational Status",
          css: "flex-1",
        },
        // { field: "flightstatus", name: "Flight Status", css: "flex-1" },
        { field: "dx_timestamp_utc", name: "DX Timestamp UTC", css: "flex-1" },
        { field: "model_refreshed", name: "Model Refresh", css: "flex-1" },
        { field: "pax_updated", name: "PAX Updated", css: "flex-1" },
        { field: "airline", name: "Airline", css: "flex-1" },
        { field: "flightnumber", name: "Flight Number", css: "flex-1" },
        { field: "sector", name: "Sector", css: "flex-1" },
        { field: "departuredate", name: "Departure Date", css: "flex-1" },
        { field: "aircrafttail", name: "Aircraft Tail", css: "flex-1" },
        { field: "aircrafttype", name: "Aircraft Type", css: "flex-1" },
        { field: "departuregate", name: "Departure Gate", css: "flex-1" },
        { field: "arrivalgate", name: "Arrival Gate", css: "flex-1" },
        {
          field: "servicetypeinbound",
          name: "Inbound Service",
          css: "flex-1",
        },
        {
          field: "servicetypeoutbound",
          name: "Outbound Service",
          css: "flex-1",
        },
      ],
    };
  },
  created() {
    this.get_reports();
    this.refresh();
  },
  methods: {
    async update_status(status) {
      this.dx_ing = true;
      await API.update("4C2CF7AD2EFE4146A51996525E878E7D", this.item.id, {
        operationalstatus: status,
      });

      this.dx_ing = false;
      this.refresh();
    },
    async get_reports() {
      let resp = await API.get("7A10DB62BCCB4995868306DD44F57B4F", {
        flrkey: this.flrkey,
      });

      if (resp) {
        this.reports = resp.data.data;
      }
    },
    async refresh() {
      this.loading = true;
      let resp = await API.get("4C2CF7AD2EFE4146A51996525E878E7D", {
        flrkey: this.flrkey,
      });

      if (resp) {
        this.item = resp.data.data[0];
        this.line_of_flying();
        this.loading = false;
      }
    },
    async line_of_flying() {
      this.lof_loading = true;
      let resp = await API.get("4C2CF7AD2EFE4146A51996525E878E7D", {
        _fields: ["sector", "std", "sta", "lof_rank", "flightnumber", "flrkey"],
        aircrafttail: this.item.aircrafttail,
        departuredate:
          dayjs(this.item.departuredate)
            .subtract(1, "days")
            .format("YYYY-MM-DD") +
          " to " +
          dayjs(this.item.departuredate).format("YYYY-MM-DD"),
        operationalstatus: "<>DX",
        orderBy: "lof_rank",
      });

      if (resp) {
        this.lof_data = _map(resp.data.data, (i) => {
          i.day = dayjs(i.std).format("Do");
          i.std = dayjs(i.std).format("HH:mm");
          i.sta = dayjs(i.sta).format("HH:mm");
          return i;
        });
        this.lof_loading = false;
      }
    },
  },
};
</script>

<style>
</style>